var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[_c('v-container',{staticClass:"app-body"},[_c('div',{staticClass:"pe-2 ps-3 app-title text-h5"},[_vm._v(" "+_vm._s(_vm.$t("string.collection"))+" "),_c('v-spacer'),_c('CloseBtn',{attrs:{"size":"40","callback":_vm.exit}})],1),_c('div',{staticClass:"pa-5"},[_c('v-row',[_vm._l((_vm.items),function(item,i){return _c('v-col',{key:i,staticStyle:{"position":"relative"},attrs:{"cols":"4"}},[_c('v-img',{staticClass:"menu-item",attrs:{"src":item.icon,"width":"150"},on:{"click":() => {
                    _vm.$intro().exit();
                    _vm.$router.push({
                      name: 'PageCollection',
                      params: {
                        type: item.name,
                      },
                    });
                  }}})],1)}),_vm._l((12 - _vm.items.length),function(i){return _c('v-col',{key:i + 7,attrs:{"cols":"4"}},[_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"aspect-ratio":"1"}},[_c('v-icon',{attrs:{"color":"brown lighten-2"}},[_vm._v("mdi-circle")])],1)])})],2)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }