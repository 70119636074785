<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            {{$t("string.collection")}}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="pa-5">
            <v-row>
              <v-col
                cols="4"
                v-for="(item, i) in items"
                :key="i"
                style="position: relative"
              >
                <v-img
                  :src="item.icon"
                  width="150"
                  class="menu-item"
                  @click="
                    () => {
                      $intro().exit();
                      $router.push({
                        name: 'PageCollection',
                        params: {
                          type: item.name,
                        },
                      });
                    }
                  "
                >
                </v-img>
              </v-col>
              <v-col cols="4" v-for="i in 12 - items.length" :key="i + 7">
                <div
                  style="aspect-ratio: 1"
                  class="d-flex align-center justify-center"
                >
                  <v-icon color="brown lighten-2">mdi-circle</v-icon>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    items: [],
  }),
  created() {
    //
  },
  mounted() {
    this.items = [
      {
        name: "gem",
        icon: require("@/assets/explore/menu/collection_gem.png"),
      },
      {
        name: "fish",
        icon: require("@/assets/explore/menu/collection_fish.png"),
      },
      {
        name: "bug",
        icon: require("@/assets/explore/menu/collection_bug.png"),
      },
      {
        name: "fruit",
        icon: require("@/assets/explore/menu/collection_fruit.png"),
      },
      {
        name: "food",
        icon: require("@/assets/explore/menu/collection_food.png"),
      },
      {
        name: "doll",
        icon: require("@/assets/explore/menu/collection_doll.png"),
      },
      {
        name: "fish2",
        icon: require("@/assets/explore/menu/collection_fish_2.png"),
      },
    ];
  },
  methods: {
    exit() {
      this.$router.push({
        name: "PageExploreMenu",
      });
    },
  },
};
</script>

<style scoped>
.menu-button {
  padding: 0 5px;
  cursor: pointer;
  width: 33.33%;
  display: flex;
  justify-content: center;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  scale: 1.1;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}

.text-stroke-sm {
  color: #fff;
  text-shadow: #8b5629 3px 0px 0px, #8b5629 2.83487px 0.981584px 0px,
    #8b5629 2.35766px 1.85511px 0px, #8b5629 1.62091px 2.52441px 0px,
    #8b5629 0.705713px 2.91581px 0px, #8b5629 -0.287171px 2.98622px 0px,
    #8b5629 -1.24844px 2.72789px 0px, #8b5629 -2.07227px 2.16926px 0px,
    #8b5629 -2.66798px 1.37182px 0px, #8b5629 -2.96998px 0.42336px 0px,
    #8b5629 -2.94502px -0.571704px 0px, #8b5629 -2.59586px -1.50383px 0px,
    #8b5629 -1.96093px -2.27041px 0px, #8b5629 -1.11013px -2.78704px 0px,
    #8b5629 -0.137119px -2.99686px 0px, #8b5629 0.850987px -2.87677px 0px,
    #8b5629 1.74541px -2.43999px 0px, #8b5629 2.44769px -1.73459px 0px,
    #8b5629 2.88051px -0.838247px 0px;
}

.main-body {
  /*background-color: #c7e5e9;*/
  background-color: rgba(214, 132, 49, 0.3);
}

.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}
</style>